<template>
    <div>
        <div class="contain1">
            <div>
                状态
                <el-select clearable  size="mini" class="m1150 select230" v-model="page.generalStatus" placeholder="请选择" @change="inquire">
                    <el-option
                        v-for="item in openstatus"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
                模板名称
                <el-input size="mini" type="text" v-model="page.tplName" placeholder="请输入模板名称" class="m1150 input340"></el-input>

                <el-button size="mini" type="primary" class="ml20" icon="el-icon-search" @click="inquire">查询</el-button>
                <el-button class="ml20" size="mini" icon="el-icon-refresh" @click="resetSearch">重置</el-button>
            </div>
        </div>
        <div class="contain2">
            <div class="btn-top">
                <el-button type="primary" size="mini" @click="add">添加</el-button>
            </div>
            <el-table
                :data="tableData"
                ref="singleTable"
                style="width: 100%;margin-bottom:40px"
                border
                :header-cell-style="{background:'#f8f8f9'}">
                <el-table-column
                    align="center"
                    type="index"
                    :index="indexMethod"
                    label="序号"
                    width="50">
                </el-table-column>
                <el-table-column prop="tplName" label="模板名称" width="400"></el-table-column>
                <el-table-column
                    align="center"
                    prop="itemCount"
                    label="检查项数量">
                </el-table-column>
                <el-table-column
                    label="使用情况"
                    align="center">
                    <template slot-scope="{ row }">
                        <span :class="[row.serviceCondition=='未使用' ? 'unused' : 'used']">{{ row.serviceCondition }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    align="center"
                    label="状态">
                    <template slot-scope="{ row }">
                        <span :class="[row.generalStatus==1 ? 'used' : 'unused']">{{ row.generalStatus==1 ? '已启用' : '已停用' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="left">
                    <template slot-scope="{row}">
                        <el-button type="text" size="small" @click="lookmuban(row)">查看</el-button>
                        <el-button type="text" size="small" @click="edit(row)">编辑</el-button>
                        <el-button v-if="row.generalStatus==1" type="text" @click="stopOrUse(row)" size="small" style="color:rgb(255, 196, 0)">停用</el-button>
                        <el-button v-else-if="row.generalStatus==0" type="text" @click="stopOrUse(row)" size="small" style="color:rgb(21, 201, 111)">启用</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin: 10px 0">
                <el-pagination
                    style="text-align:center"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                    :page-size="page.size"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
            <el-dialog
                :title="editStatus == 1 ? '添加模板' : (editStatus == 2 ? '编辑模板' : '查看模板')"
                :visible.sync="dialogVisible"
                width="60%"
                custom-class="mubanDialog"
                :close-on-click-modal="false"
                @closed="reset">
                <el-row :gutter="20" v-if="editStatus == 1 || editStatus == 2">
                    <el-col :span="9">
                        <el-form ref="form" :model="form" label-width="130px" :rules="rule">
                            <el-form-item label="模板名称" prop="tplName">
                                <el-input v-model="form.tplName" size="small" style="width:100%"></el-input>
                            </el-form-item>
                            <el-form-item label="启用状态" prop="generalStatus">
                                <el-radio :label="1" v-model="form.generalStatus">启用</el-radio>
                                <el-radio :label="0" v-model="form.generalStatus">禁用</el-radio>
                            </el-form-item>
                            <el-form-item label="备注">
                                <el-input type="textarea" v-model="form.remarks" rows="3"></el-input>
                            </el-form-item>
                        </el-form>
                    </el-col>
                    <el-col :span="13" :offset="1">
                        <p class="lable-custom"><font>*</font>检查项</p>
                        <el-card class="box-card" :body-style="{height: '500px'}" style="max-height:500px;overflow: auto">
                            <div slot="header" class="clearfix">
                                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                                <span style="float: right; padding: 3px 0;color: #EE293C">{{ form.checkTemplates.length }}/{{ checkList.length }}</span>
                            </div>
                            <el-checkbox-group style="" v-model="form.checkTemplates" @change="handleCheckedItemChange">
                                <div v-for="item in checkList" :key="item.id" class="mb5">
                                    <el-checkbox :label="item.id">{{item.itemName}}</el-checkbox>
                                </div>
                            </el-checkbox-group>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row :gutter="20" v-else>
                    <el-col :span="18" :offset="3">
                        <el-descriptions :column="2" border :colon="false" :labelStyle="{fontWeight: 'bold',width: '80px'}" :contentStyle="{color: 'LightSteelBlue'}">
                            <el-descriptions-item label="模板名称">{{chosedRow.tplName}}</el-descriptions-item>
                            <el-descriptions-item label="启用状态">
                                {{ chosedRow.generalStatus ? '启用' : '禁用' }}
                            </el-descriptions-item>
                            <el-descriptions-item label="备注">{{ chosedRow.remarks }}</el-descriptions-item>
                        </el-descriptions>
                        <div style="margin: 24px 0 11px">检查项</div>
                        <el-table
                            :data="chosedRow.item"
                            style="margin-bottom:40px"
                            border
                            :header-cell-style="{background:'#f8f8f9'}">
                            <!-- <el-table-column type="expand">
                                <template slot-scope="props">
                                    <el-table
                                        :data="props.row.items"
                                        border
                                        style="margin: 20px;width:95%">
                                        <el-table-column
                                            align="center"
                                            type="index"
                                            label="序号"
                                            width="50">
                                        </el-table-column>
                                        <el-table-column prop="content" label="内容" width="300"> </el-table-column>
                                        <el-table-column prop="type" label="分类"> </el-table-column>
                                        <el-table-column prop="takingPictures" label="是否拍照"> </el-table-column>
                                        <el-table-column prop="describe" label="是否补充描述"> </el-table-column>
                                    </el-table>
                                </template>
                            </el-table-column> -->
                            <el-table-column
                                align="center"
                                type="index"
                                label="序号"
                                width="50">
                            </el-table-column>
                            <el-table-column label="检查项目">
                                <template slot-scope="props">
                                    <el-popover
                                        placement="right"
                                        width="800"
                                        trigger="click">
                                        <el-table
                                            :data="props.row.items"
                                            border>
                                            <el-table-column
                                                align="center"
                                                type="index"
                                                label="序号"
                                                width="50">
                                            </el-table-column>
                                            <el-table-column prop="content" label="内容" width="300"> </el-table-column>
                                            <el-table-column prop="type" label="分类"> </el-table-column>
                                            <el-table-column prop="takingPictures" label="是否拍照"> </el-table-column>
                                            <el-table-column prop="describe" label="是否补充描述"> </el-table-column>
                                        </el-table>
                                        <el-button type="text" slot="reference">{{props.row.itemName}}</el-button>
                                    </el-popover>
                                </template>
                            </el-table-column>
                            <el-table-column prop="itemNote" label="检查内容" width="320"> </el-table-column>
                            <el-table-column prop="itemType" label="分类"> </el-table-column>
                            <el-table-column prop="requiredFields" label="必填项"> </el-table-column>
                            <el-table-column prop="inputType" label="录入类型"> </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
                <span slot="footer" class="dialog-footer" v-if="editStatus == 1 || editStatus == 2">
                    <el-button type="primary" size="medium" @click="saveForm" v-if="editStatus==1">保存</el-button>
                    <el-button type="primary" size="medium" @click="updateForm" v-else>保存</el-button>
                    <el-button type="info" size="medium" @click="dialogVisible=false">取消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import {
        checkTpl,
        checkTemplateSave,
        checkTemplateUpdate,
        checkTemplateDeleteById,
        selectTemplateList,
        selectItemByTemplateId,
        startOrStop} from '../../RequestPort/tempManage/tempMaint.js';
export default {
    name: 'tempMaint',
    data() {
        return {
            form: {
                tplName: '',
                generalStatus: 1, // 启用状态
                remarks: '',
                checkTemplates: [] // 检查项
            },
            rule:{
                tplName: [
                    { required: true, message: '请输入模板名称', trigger: 'blur' },
                    { max: 20, message: '最长20个字符', trigger: ['change','blur'] }
                ],
                generalStatus: [{ required: true }]
            },
            editStatus: 1, // 1显示保存2显示修改
            tableData: [],
            switchValue: false,
            page: {
                current: 1,
                size: 100,
                tplName: '',
                generalStatus: ''
            },
            findObj: {},
            total: 0,
            dialogVisible: false,
            types:[{label:"民用用户",id:1},{label:"商用用户",id:2},{label:"工业用户",id:3},{label:'村村通用户',id:4},{label:'调压箱',id:5},{label:'管线',id:6}],
            openstatus: [{id: 1,label: '已启用'},{id: 0,label: '已停用'}],
            checkList: [], // 检查项列表
            checkAll: false,
            isIndeterminate: true,
            chosedRow: {},
            selectedList: []
        }
    },
    mounted () {
        this.objCopy()
        this.getTableData()
    },
    methods: {
        changeEditStatus () {
            this.edit(this.chosedRow)
        },
        lookmuban (row) {
            this.editStatus = 3
            this.dialogVisible = true
            checkTpl({id: row.id}).then(res => {
                console.log(res.data)
                this.chosedRow = res.data
            })
            
        },
        handleCheckAllChange(val) {
            this.form.checkTemplates = []
            if (val) {
                this.checkList.forEach(item => {
                    this.form.checkTemplates.push(item.id)
                })
            }
            this.isIndeterminate = false;
        },
        handleCheckedItemChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.checkList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkList.length;
        },
        inquire () {
            this.page.current = 1
            this.objCopy()
            this.getTableData()
        },
        stopOrUse (row) {
            console.log(row);
            startOrStop({
                id: row.id,
                generalStatus: row.generalStatus == 1 ? 0 : 1
            }).then(res => {
                this.$message.success('操作成功')
                this.getTableData()
            })
        },
        edit (row) {
            this.editStatus = 2
            this.dialogVisible = true
            if (row) {
                console.log(row);
                this.$nextTick(() => {
                    checkTpl({id: row.id}).then(res => {

                        let objKey = Object.keys(res.data)
                        objKey.forEach(item => {
                            if (item == 'item') {
                                this.form.checkTemplates = res.data[item].map(inner => {
                                    return inner.id
                                })
                            } else {
                                this.form[item] = res.data[item]
                            }
                        })
                    })
                    this.getShuttleList()
                })
            }
            
        },
        add () {
            this.dialogVisible = true
            this.$nextTick(() => {
                this.reset()
                this.editStatus = 1
                this.checkList = []
                this.checkAll = false
                this.getShuttleList()
            })
        },
        indexMethod (index) {
            return (this.page.current - 1) * this.page.size + index + 1
        },
        objCopy () {
            for (let key in this.page){
                this.findObj[key] = this.page[key];
            }
        },
        updateForm () {
            let obj = {}
            for (let key in this.form) {
                if (key == 'checkTemplates') {
                    obj[key] = []
                    this.form[key].forEach(item => {
                        obj[key].push({
                            itemId: item
                        })
                    })
                } else {
                    obj[key] = this.form[key]
                }
            }
            checkTemplateUpdate(obj).then(res => {
                if(res.code === 200) {
                    this.editStatus = 1
                    this.getTableData()
                    this.$message.success(res.msg)
                    this.reset()
                    this.dialogVisible = false
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        saveForm () {
             this.$refs['form'].validate((valid) => {
                if (valid) {
                    let obj = {}
                    for (let key in this.form) {
                        if (key == 'checkTemplates') {
                            obj[key] = []
                            this.form[key].forEach(item => {
                                obj[key].push({
                                    itemId: item
                                })
                            })
                        } else {
                            obj[key] = this.form[key]
                        }
                    }
                    checkTemplateSave(obj).then(res => {
                        if (res.code === 200) {
                            this.getTableData()
                            this.$message.success(res.msg)
                            this.reset()
                            this.dialogVisible = false
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                };
            });
        },
        del (row) {
            this.$confirm(`是否删除模板---[ ${row.tplName} ]?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                closeOnClickModal:false,
                type: 'warning'
            }).then(() => {
                checkTemplateDeleteById({id: row.id}).then(res => {
                    if(res.code === 200) {
                        this.editStatus = 1
                        this.getTableData()
                        this.$message.success(res.msg)
                        this.reset()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            })
        },
        resetSearch () {
            this.page = {
                current: 1,
                size: 100,
                tplName: '',
                generalStatus: ''
            }
            this.objCopy()
            this.getTableData()
        },
        reset () {
            if (this.form.id) delete this.form.id
            if (this.form.deleteflag) delete this.form.deleteflag
            this.form = {
                tplName: '',
                generalStatus: 1, // 启用状态
                remarks: '',
                checkTemplates: [] // 检查项
            }
            this.$refs['form'] ? this.$refs['form'].resetFields() : ''
        },
        async getShuttleList () {
            let response
            console.log(this.form.usertype);
            await selectItemByTemplateId().then(res => {
                if (res.code === 200) {
                    // this.selectedList = res.data.selectedList
                    this.checkList = res.data
                    // this.checkAll = res.data.selectedList.length === this.checkList.length;
                    // this.form.checkTemplates = res.data.selectedList.map(item => {
                    //     return item.id
                    // })
                    response = this.form.checkTemplates
                } else {
                    this.$message.error(res.msg)
                }
            })
            return response
        },
        showAll (newVal) { // 切换全部显示
            newVal ? this.page.type = 1 : this.page.type = 0
            this.getTableData()
        },
        getTableData () {
            selectTemplateList(this.findObj).then(res => {
                if (res.code === 200) {
                    let { total,records } = res.data
                    this.tableData = records
                    this.total = total
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        handleCurrentChange (val) {
            this.page.current = val
            this.objCopy()
            this.getTableData()
        },
        handleSizeChange (val) {
            this.page.current = 1
            this.page.size = val
            this.objCopy()
            this.getTableData()
        }
    }
}
</script>
<style lang="less" scoped>
    .lable-custom{padding-bottom:10px;
        font{color: #F56C6C;padding-right:5px;}
    }
    .el-transfer-panel {
        width: 300px;
        height: 64vh;
    }
    .el-transfer-panel__body {
        height: 100%;
    }
    .el-transfer-panel__list {
        height: 100%;
    }
    .mubanDialog {
        margin-top: 2vh !important;
    }
    .pd20 {
        padding-left: 15px;
    }
    .mb5 {
        margin-bottom: 5px;
    }
    .used {
        color: #0C235B;
    }
    .unused {
        color: #FF5400;
    }
</style>