import axios from '@/common/js/request'

export function checkTemplateSave(data){  // 新增模板
    const checkTemplateSave = axios({
        url: '/checkTemplate/saveTempl',
        method: 'post',
        data
    })
    return checkTemplateSave
}

export function checkTemplateUpdate(data){  // 修改模板
    const checkTemplateUpdate = axios({
        url: '/checkTemplate/updateTempl',
        method: 'post',
        data
    })
    return checkTemplateUpdate
}

export function checkTemplateDeleteById(params){  // 删除模板
    const checkTemplateDeleteById = axios({
        url: '/CheckZyTemplate/deleteById',
        method: 'get',
        params
    })
    return checkTemplateDeleteById
}

export function selectTemplateList(params){ // 分页查询模板列表
    const selectTemplateList = axios({
        url:'/checkTemplate/selectTpl',
        method: 'get',
        params
    })
    return selectTemplateList
}

export function selectItemByTemplateId(params){ // 检查项
    const selectItemByTemplateId = axios({
        url:'/checkItem/selectTemplItem',
        method: 'get',
        params
    })
    return selectItemByTemplateId
}

export function checkTpl(params){ // 查看模板
    const checkTpl = axios({
        url:'/checkTemplate/checkTpl',
        method: 'get',
        params
    })
    return checkTpl
}

export function startOrStop(params){  // 模板启用停用
    const startOrStop = axios({
        url: '/checkTemplate/updateTplStatus',
        method: 'get',
        params
    })
    return startOrStop
}